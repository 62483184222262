const SessionManager = {
    getToken() {
        const token = {token:sessionStorage.getItem('token'), date:sessionStorage.getItem('tokenDate')};
        if (token) return token;
        else return null;
    },

    setUserSession(token, date) {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('tokenDate', date);
    },

    removeUserSession(){
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('tokenDate');
    }
}

export default SessionManager;
