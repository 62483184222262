import React from 'react'
import SessionManager from './sessionManager'
import { Navigate, redirect } from 'react-router-dom'

function AuthenticationControl({children}){
    const token = SessionManager.getToken()
    if(token.token){
        const date = new Date()
        const tarih = token.date.split('-')
        const tokenDate = new Date(tarih[1] + "."+ tarih[0] + "." + tarih[2])
        
        if(tokenDate <= date)
            return <Navigate to="/login" />
    }else
        return <Navigate to="/login" />
    
    return children 
}

export default AuthenticationControl