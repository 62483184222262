import * as React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import AuthenticationControl from "./AuthenticationControl";
import Giris from "./views/Giris"
import EmailAddresses from "./views/EmailAddresses"
import EmailScraper from "./views/EmailScraper";
import TargetedScraper from "./views/TargetedScraper";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthenticationControl><Root/></AuthenticationControl>,
    errorElement:<ErrorPage/>,
        children: [
      {
        index:true,
        element: <EmailScraper />,
      },
      {
        path:"email-addresses",
        element:<EmailAddresses />
      },
      {
        path:"targeted-scraper",
        element:<TargetedScraper/>
      },
    ],
  },
  {
    path: "login",
    element: <Giris />,
  }
  
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);