import React, { useState, useEffect } from "react"
import TableSource from '../components/TableSource'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import IconButton from "@mui/material/IconButton";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import services from "../services";
import toast from "react-hot-toast";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import SaveIcon from '@mui/icons-material/Save';

const EmailScraper = ()=>{
    const [siralama, setSiralama] = useState(0);
    const [listeFiltresi, setListeFiltresi] = useState('');
    const [ulkeFiltresi, setUlkeFiltresi] = useState('');
    const [arsiv, setArsiv] = useState(0);
    const [epostaListeleri, setEpostaListeleri] = useState([]);
    const [ulkeler, setUlkeler] = useState([]);
    const [topPage, setTopPage] = React.useState(0);
    const [openFiltre, setOpenFiltre] = useState(false);
    const [openKazima, setOpenKazima] = useState(false);
    const [filtreler, setFiltreler] = useState({srlm:0, lf:'', uf:''});
    const [postaListesi, setPostaListesi] = useState('');
    const [yeniPostaListesi, setYeniPostaListesi] = useState('');
    const [ulke, setUlke] = useState('');
    const [listeDisabled, setListeDisabled] = useState(true);
    const [yeniListeDisabled, setYeniListeDisabled] = useState(true);
    const [kaziBtnDis, setKaziBtnDis] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [tasksLoading, setTasksLoading] = useState(false);
    const [mesaj, setMesaj] = useState('');
    const [kelime, setKelime] = useState('');
    const [kelimeDisabled, setKelimeDisabled] = useState(true);
    const [selected, setSelected] = useState([]);
    const [silOpen, setSilOpen] = useState(false);
    const [durum, setDurum] = useState(null);

function FiltreIptal(){
    setOpenFiltre(false);
    setSiralama(filtreler.srlm);
    setListeFiltresi(filtreler.lf);
    setUlkeFiltresi(filtreler.uf);
}

function KazimaIptal(){
    setOpenKazima(false)
    setUlke('')
    setYeniPostaListesi('')
    setYeniListeDisabled(true)
    setPostaListesi('')
    setListeDisabled(true)
}

function ChangeUlke(id){
    setUlke(id)
    if(id){
        setListeDisabled(false)
        setYeniListeDisabled(false)
    }else{
        setPostaListesi('')
        setListeDisabled(true)
        setYeniPostaListesi('');
        setYeniListeDisabled(true);
        setKelime('')
        setKelimeDisabled(true);
    }
}

function ChangeKelime(value){
    setKelime(value)
    if(value){
       setKaziBtnDis(false)
    }else{
       setKaziBtnDis(true)
    }
 }

 async function FiltreTemizle(){
    setSiralama(0)
    setListeFiltresi('')
    setUlkeFiltresi('')
 }

function KazimaKayit(){
    const toastId=toast.loading('Kayıt tamamlanıyor lütfen bekleyin.');
    const data={
        ulkeId:ulke,
        listeId:parseInt(postaListesi),
        listeAd:yeniPostaListesi,
        kelime:kelime
    }

    KazimaIptal()
    services.postAxios('Scraper/InsertScraperTask',data).then((res)=>{
        toast.success('Kayıt işlemi başırılı bir şekilde tamamlandı.', {id:toastId, duration:2500})
        GetTasks(1, 0, null, null, 0)
        FiltreTemizle()
        setFiltreler({srlm:0,lf:'',uf:''})
    }).catch((err)=>{
        toast.error('Kayıt işlemi sırasında bir hata oluştu.', {id:toastId, duration:3000})
    })
}

function GetTasks(page, srlm, lf, uf, arsv){
    setTasks([]);
    setTasksLoading(true);
    services.getAxios('Scraper/ScraperTasks?siralama='+(srlm || (siralama == 1 ? true : false))+'&listeId='
    +(lf || listeFiltresi)+'&ulkeId='+(uf || ulkeFiltresi)+'&durum='+(arsv===0||arsv===1?arsv:arsiv)+'&page='+page).then((res)=>{
        if(res.data.task.length < 1)
            setMesaj('Hiç e-posta kazıma işlemi yapılmamış.')
        setTasks(res.data.task)
        setTopPage(res.data.topPage)
        setTasksLoading(false)
    }).catch((err)=>{
        setMesaj('Bir hata oluştu.')
        toast.error('E-posta kazıma listesi yüklenirken bir hata oluştu.', {duration:3000})
        setTasksLoading(false)
    })
}

function TaskSil(){
    setSilOpen(false)
    const toastId = toast.loading('Kazıma işlemleri siliniyor. Lütfen bekleyin!');

    services.postAxios('Scraper/DeleteScraper',selected).then((res)=>{
        toast.success('Silme işlemi tamamlandı.', {id:toastId, duration:2500})
        GetTasks(1)
    }).catch((err)=>{
        toast.error('Silme işlemi sırasında bir hata oluştu.', {id:toastId, duration:3000})
    })
}

function ArsivDegistir(){
    const toastId=toast.loading('İşlem sürüyor. Lütfen bekleyin.')

    services.postAxios('scraper/ArsivDegistir?arsiv=true',selected).then((res)=>{
        toast.success('İşlem tamamlandı.',{id:toastId, duration:2500});
        GetTasks(1)
    }).catch(()=>{
        toast.error('İşlem sırasında bir hata oluştu.',{id:toastId, duration:3000});
    })
}

function ChangeArsiv(event){
    const arsiv=event.target.checked?1:0;
    setArsiv(arsiv)
    GetTasks(1,siralama, listeFiltresi, ulkeFiltresi, arsiv);
}

async function DurumDegistir(secenek){
    const toastId=toast.loading('İşlem sürüyor lütfen bekleyin!');
    if(secenek === 1){
        await services.getAxios('Scraper/Pause?id='+selected[0]).then((res)=>{
            toast.success('İşlem tamamlandı.',{duration:2500, id:toastId});
        }).catch((err)=>{
            toast.error('İşlem sırasında bir hata oluştu.',{duration:3000, id:toastId});
        })
    }else{
        await services.getAxios('Scraper/Continue?id='+selected[0]).then((res)=>{
            toast.success('İşlem tamamlandı.',{duration:2500, id:toastId});
        }).catch((err)=>{
            toast.error('İşlem sırasında bir hata oluştu.',{duration:3000, id:toastId})       
        })
    }

    GetTasks(1);
}

async function Excel(){
    await services.getAxios('Scraper/Excel?aramaId=' + selected[0]).then((res)=>{
        const d =[]
        res.data?.forEach((item)=>{
            d.push({"Web sitesi":item.webSitesi, "İşletme Türü":item.turu, 
        "E-posta adresi": item.adres, "Telefon numarası": item.telefon})});
        
        const ws = XLSX.utils.json_to_sheet(d)
        const wb = {Sheets:{'data':ws}, SheetNames: ['data']}
        const excelBuffer =XLSX.write(wb, {bookType:'xlsx', type:'array'});
        const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        FileSaver.saveAs(data, 'E-posta adresleri.xlsx')
    }).catch(()=>{})  
}

useEffect(()=>{
    GetTasks(1)
},[])

useEffect(()=>{
    services.getAxios('CountryStateCity/Countries').then((res)=>{
       setUlkeler(res.data)
    }).catch((err)=>{
       toast.error('Ülkeler yüklenirken bir hata oluştu.',{duration:3000});
    })

    services.getAxios('PostaListeleri/PostaListeleri').then((res)=>{
        setEpostaListeleri(res.data)
      }).catch(()=>{
        toast.error('E-posta listeleri yüklenirken bir hata oluştu.',{duration:3000});
      })
 },[])

useEffect(()=>{
    if(yeniPostaListesi)
      setPostaListesi('')
  },[yeniPostaListesi])

  useEffect(()=>{
    if(postaListesi)
      setYeniPostaListesi('')
  },[postaListesi])

  useEffect(()=>{
    if(postaListesi || yeniPostaListesi){
        setKelimeDisabled(false)
        ChangeKelime('')
    }else{
        setKelimeDisabled(true)
        ChangeKelime('')
    }
  },[postaListesi, yeniPostaListesi])

    return(
      <>
        <div className="d-lg-flex justify-content-between">
            <div className="d-lg-flex gap-3">
                <div className="mb-2">
                    <Button variant="outlined" className="text-capitalize" fullWidth
                    onClick={()=>{setOpenKazima(true)}}
                    startIcon={<AddRoundedIcon/>}>E-posta Kazı</Button>
                </div>
                <div className="mb-2">
                    <Button variant="outlined" color="error" className="text-capitalize" fullWidth
                    disabled={selected.length > 0 ? false:true}
                    onClick={()=>{setSilOpen(true)}}
                    startIcon={<DeleteOutlineRoundedIcon/>}>Sil</Button>
                </div>
                <div className="mb-2">
                    <Button variant="outlined" color="success" className="text-capitalize" fullWidth
                    disabled={selected.length > 0 ? arsiv === 1?true:false:true}
                    onClick={ArsivDegistir}
                    startIcon={<BookmarkBorderOutlinedIcon/>}>Arşivle</Button>
                </div>
                <div className="mb-2">
                    <Button variant="outlined" color="warning" className="text-capitalize" fullWidth
                    disabled={selected.length === 1 ? durum !== 1&& durum !==2?false:true:true}
                    onClick={()=>DurumDegistir(1)}
                    startIcon={<PauseCircleOutlineRoundedIcon/>}>Duraklat</Button>
                </div>
                <div className="mb-2">
                    <Button variant="outlined" color="secondary" className="text-capitalize" fullWidth
                    disabled={selected.length === 1 ? durum !== 1?true:false:true}
                    onClick={()=>DurumDegistir(2)}
                    startIcon={<PlayCircleOutlineRoundedIcon/>}>Başlat</Button>
                </div>
                <div className="mb-2">
                    <Button variant="outlined" color="success" startIcon={<SaveIcon/>} 
                        disabled={selected.length === 1 ? false : true}
                        fullWidth className="text-capitalize" onClick={Excel}>
                        İndir
                    </Button>
                </div>
            </div>
            <div className="mb-2 float-end d-flex">
                <div className="my-auto">
                <FormControlLabel className="m-0" control={<Switch checked={arsiv===0?false:true} 
                size="small"/>} label="Arşiv" onChange={ChangeArsiv}/>
                </div>
                <div>
                    <IconButton onClick={()=>setOpenFiltre(true)}><FilterListRoundedIcon/></IconButton>
                </div>
            </div>
        </div>
        <TableSource liste={tasks} loading={tasksLoading} mesaj={mesaj} TopPage={topPage} 
        ChangePage={(value)=>{GetTasks(value)}} selected={selected} setSelected={setSelected} setDurum={setDurum}/>

        <Dialog open={openFiltre} onClose={FiltreIptal} maxWidth="xs" fullWidth>
            <DialogTitle>Filtreler</DialogTitle>
            <DialogContent>
                <FormControl fullWidth size="small" className="my-3">
                    <InputLabel id="siralama">Sıralama</InputLabel>
                    <Select
                    labelId="siralama" value={siralama}
                    id="demo-simple-select-siralama" onChange={(event)=>setSiralama(event.target.value)}
                    label="Sıralama">
                        <MenuItem value="0">Tarihe Göre (Önce Yeni)</MenuItem>
                        <MenuItem value="1">Tarihe Göre (Önce Eski)</MenuItem>
                    </Select>
                </FormControl>
                {/* <FormControl fullWidth size="small" className="mb-3">
                    <InputLabel id="durum">Durum</InputLabel>
                    <Select
                    labelId="durum" value={durum}
                    id="demo-simple-select-durum" onChange={(event)=>setDurum(event.target.value)}
                    label="Durum">
                        <MenuItem value="0">Tamamlanmamış</MenuItem>
                        <MenuItem value="1">Tamamlanmış</MenuItem>
                        <MenuItem value="2">Tümü</MenuItem>
                    </Select>
                </FormControl> */}
                <FormControl fullWidth size="small" className="mb-3">
                    <InputLabel id="filtre-liste">E-posta Listesi</InputLabel>
                    <Select
                    labelId="filtre-liste" value={listeFiltresi}
                    id="demo-simple-select-filtre-liste" onChange={(event)=>setListeFiltresi(event.target.value)}
                    label="E-posta Listesi">
                        {epostaListeleri?.filter((item)=> item.filtre === true)?.map((item)=>(
                            <MenuItem value={item.id}>{item.ad}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                    <InputLabel id="filtre-ulke">Ülke</InputLabel>
                    <Select
                    labelId="filtre-ulke" value={ulkeFiltresi}
                    id="demo-simple-select-filtre-ulke" onChange={(event)=>setUlkeFiltresi(event.target.value)}
                    label="Ülke">
                        {ulkeler?.filter((item)=>item.filtre === true)?.map((item)=>(
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions className="justify-content-between">
                <Button onClick={FiltreTemizle} color="error">Temizle</Button>
                <div>
                    <Button onClick={FiltreIptal} color="error">İptal</Button>
                    <Button onClick={()=>{setOpenFiltre(false); GetTasks(1); 
                        setFiltreler({srlm:siralama, lf:listeFiltresi, uf:ulkeFiltresi})}}>Uygula</Button>
                </div>
            </DialogActions>
        </Dialog>

        <Dialog open={openKazima} onClose={KazimaIptal} maxWidth="xs" fullWidth>
            <DialogTitle>E-posta Kazıma</DialogTitle>
            <DialogContent>
                <DialogContentText className="mb-2 ms-sm-3">
                Hangi ülkede arama yapılacağını seçin.
                </DialogContentText>
                <Autocomplete
                    id="ulke" className="mb-4"
                    size="small" 
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, value)=>{ChangeUlke(value?.id)}}
                    options={ulkeler}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Ülkeler" />}
                    />
                <DialogContentText className="mb-2 ms-sm-3">
                E-postaların kayıt edileceği bir liste seçin.
                </DialogContentText>
                <FormControl fullWidth size="small" className="mb-3" disabled={listeDisabled}>
                    <InputLabel id="filtre-liste">E-posta Listesi</InputLabel>
                    <Select disabled={listeDisabled}
                    labelId="filtre-liste" value={postaListesi}
                    id="demo-simple-select-filtre-liste" onChange={(event)=>setPostaListesi(event.target.value)}
                    label="E-posta Listesi">
                        {epostaListeleri?.map((item)=>(
                            <MenuItem value={item.id}>{item.ad}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField id="outlined-basic-liste" size="small" fullWidth label="Yeni E-posta Listesi" 
                variant="outlined" disabled={yeniListeDisabled} value={yeniPostaListesi} 
                onChange={(event)=>{setYeniPostaListesi(event.target.value)}} className="mb-4"/>
                <DialogContentText className="mb-2 ms-sm-3">
                Aranacak bir kelime girin.
                </DialogContentText>
                 <TextField id="outlined-basic-kelime" size="small" fullWidth label="Aranacak Kelime" 
                 variant="outlined" disabled={kelimeDisabled} value={kelime}
                onChange={(event)=>{ChangeKelime(event.target.value)}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={KazimaIptal} color="error">İptal</Button>
                <Button disabled={kaziBtnDis} onClick={KazimaKayit}>Başlat</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={silOpen} onClose={()=>setSilOpen(false)} maxWidth="xs" fullWidth>
            <DialogTitle>Kazıma İşlemleri Silinecek</DialogTitle>
            <DialogContent>
            <DialogContentText className="mb-3">
                Kazıma işleminden elde edilen veriler silinecek.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>{setSilOpen(false)}}>İptal</Button>
            <Button onClick={TaskSil}>Sil</Button>
            </DialogActions>
        </Dialog>
      </>
    )
}

export default EmailScraper