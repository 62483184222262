import "./css/style.css"
import { useNavigate,useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <div id="notfound">
		<div class="notfound">
			<div class="notfound-bg">
				<div></div>
				<div></div>
				<div></div>
			</div>
			<h1>oops!</h1>
			<h2>{error.status === 404 ? 'Hata 404 : Sayfa Bulunamadı': 'Bir Hata Oluştu'}</h2>
			<a onClick={()=> navigate(-1)}>GERİ DÖN</a>
		</div>
	</div>
  );
}