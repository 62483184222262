import React, { useEffect, useState } from 'react';
import ContentPasteOffSharpIcon from '@mui/icons-material/ContentPasteOffSharp';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import {Table, TableBody, TableCell, 
   TableRow, TableHead, TableContainer} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';

  const DenseTable = ({liste, loading, mesaj, selected, setSelected, setDurum}) => {
    const [allSelected, setAllSelected] = React.useState(false);
    const isSelected = (name) => selected.indexOf(name) !== -1;
  
    const handleClick = (event, name, durum) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      
      if(newSelected.length == liste.length)
        setAllSelected(true)
      else
        setAllSelected(false)
      setSelected(newSelected);
      setDurum(durum)
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = liste?.map((n) => n.id);
        setAllSelected(true)
        setSelected(newSelected);
        return;
      }
      setAllSelected(false)
      setSelected([]);
    };
  
    useEffect(()=>{
      setAllSelected(false)
      setSelected([])
    },[liste])

  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={allSelected}
                onChange={(event) => {handleSelectAllClick(event)}}
              />
            </TableCell>
            <TableCell>Dosya Adı</TableCell>
            <TableCell align='center'>Adet</TableCell>
            <TableCell align='center'>Tamamlanan</TableCell>
            <TableCell align='center'>Durum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {liste?.length > 0 ? (liste?.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            
            return (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="checkbox">
                  <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onChange={(event)=> handleClick(event, row.id,row.durum)}
                    />
              </TableCell>
              <TableCell component="th" scope="row" className='text-truncate' style={{maxWidth:"200px"}}>
                {row.dosyaAdi}</TableCell>
              <TableCell align='center' className='text-truncate' style={{maxWidth:"200px"}}>
                {row.adet}
              </TableCell>
              <TableCell align='center' className='text-truncate' style={{maxWidth:"200px"}}>
                {row.tamamlanan}
              </TableCell>
              <TableCell align='center'>
              <Chip label={row.durum ===1 ? "Duraklatıldı":row.durum===2?"Tamamlandı":"Sürüyor"} 
              color={row.durum===1?"warning":row.durum===2?"success":"info"}
              size="small"/>
              </TableCell>
            </TableRow>
          )})) : (<TableRow>
              <TableCell align='center' colSpan={8}>
                {loading? (<CircularProgress size={35} color="inherit" />): (
                    <span className='fs-3 text-black-50 d-flex justify-content-center'>
                      <ContentPasteOffSharpIcon fontSize='large' className='me-2'>
                        </ContentPasteOffSharpIcon>{mesaj || 'Kayıt bulunamadı.'}
                      </span>
                )}
              </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export default DenseTable;