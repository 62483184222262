import React, { useEffect, useState } from "react"
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TablePostaAdresleri from "../components/TablePostaAdresleri";
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import toast from "react-hot-toast";
import services from "../services";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import prefixes from "../mobile-prefixes";

const EmailAddresses=()=>{
    const [postaListeleri, setPostaListeleri] = useState([]);
    const [postaListesi, setPostaListesi] = useState('');
    const [postaAdresleri, setPostaAdresleri] = useState([]);
    const [topPage, setTopPage] = React.useState(0);
    const [backdrop, setBackdrop] = React.useState(false);
    const [btnSilLoading, setBtnSilLoading] = React.useState(false);
    const [silBtnDis, setSilBtnDis] = React.useState(true);
    const [btnExcelLoading, setBtnExcelLoading] = React.useState(false);
    const [btnExcelDis, setBtnExcelDis] = React.useState(true);
    const [selected, setSelected] = React.useState([]);
    const [mesaj, setMesaj] = useState('Lütfen bir e-posta listesi seçin.');
    const [ulkeler, setUlkeler] = useState([]);
    const [ulkelerDis, setUlkelerDis] = useState(true);
    const [turler, setTurler] = useState([]);
    const [turlerDis, setTurlerDis] = useState(true);
    const [ulkeFiltresi, setUlkeFiltresi] = useState('');
    const [turFiltresi, setTurFiltresi] = useState('');
    const [listeSilDis, setlisteSilDis] = useState(true);
    const [open, setOpen] = useState(false);
    const [prefix, setPrefix] = useState([]);

useEffect(()=>{
    if(selected.length > 0)
        setSilBtnDis(false)
    else 
        setSilBtnDis(true)
},[selected])

function OnChangePostaListesi(value){
    setPostaListesi(value)
    setUlkeFiltresi('')
    setTurFiltresi('')
    if(value){
        setlisteSilDis(false)
        setUlkelerDis(false)
        setMesaj("Lütfen bir ülke seçin.")

        services.getAxios('CountryStateCity/CountriesFilter?listeId='+value).then((res)=>{
            setUlkeler(res.data)
        }).catch((err)=>{
            toast.error('Ülkeler yüklenirken bir hata oluştu.', {duration:3000})
        })
    }else{
        setlisteSilDis(true)
        setUlkelerDis(true)
        setTurlerDis(true)
        setMesaj("Lütfen bir e-posta listesi seçin.")
    }
}

async function Excel(){
    setBtnExcelLoading(true)
    await services.getAxios('PostaAdresleri/Excel?listeId='+postaListesi+'&ulkeId='+ulkeFiltresi+
        '&tur='+turFiltresi).then((res)=>{
        const d =[]
        res.data?.forEach((item)=>{
            const tel = item.telefon.replaceAll(/-| |(|)/gi, "")
            let mo = "" 
            prefix?.map((x)=>{if(tel.startsWith(x))mo ="evet"})
            d.push({"Web sitesi":item.webSitesi, "İşletme Türü":item.turu, 
        "E-posta adresi": item.adres, "Telefon numarası": item.telefon, 
        "Mobil operatör":mo})});
        
        const ws = XLSX.utils.json_to_sheet(d)
        const wb = {Sheets:{'data':ws}, SheetNames: ['data']}
        const excelBuffer =XLSX.write(wb, {bookType:'xlsx', type:'array'});
        const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        FileSaver.saveAs(data, 'E-posta adresleri.xlsx')
    }).catch(()=>{})  
    setBtnExcelLoading(false)
}

useEffect(()=>{
    setPostaAdresleri([])
    setTopPage(0)
    if(ulkeFiltresi){
        //setUlkeSilDis(false)
        GetPostaAdresleri(1)
    }
},[ulkeFiltresi])

function GetPostaAdresleri(page){
    setBackdrop(true);
    services.getAxios('PostaAdresleri/PostaAdresleri?listeId='
        +postaListesi+'&ulkeId='+ulkeFiltresi+'&page='+page + '&tur='+turFiltresi).then((res)=>{
            if(res.data.postaAdresleri.length < 1)
                setMesaj('Hiç kayıt bulunamadı.')
            setPostaAdresleri(res.data.postaAdresleri)
            setTopPage(res.data.topPage)
            setBackdrop(false)
        }).catch(()=>{
            setBackdrop(false)
            toast.error('Posta adresleri yüklenirken bir hata oluştu.')
        })
}

useEffect(()=>{
    GetPostaListeleri()
},[])

useEffect(()=>{
    if(postaAdresleri.length > 0)
        setBtnExcelDis(false)
    else
        setBtnExcelDis(true)
},[postaAdresleri])

useEffect(()=>{
    if(!turlerDis){
        GetPostaAdresleri(1)
    }
},[turFiltresi])

const GetPostaListeleri=()=>{
    services.getAxios('PostaListeleri/PostaListeleri').then((res)=>{
        setPostaListeleri(res.data)
      }).catch(()=>{
        toast.error('E-posta listeleri yüklenirken bir hata oluştu.',{duration:3000});
      })
}

 function OnChangeUlkeler(value, name){
    setUlkeFiltresi(value)
    setTurFiltresi('')
    if(value){
        setPrefix(prefixes.byCountryName(name)?.map((pre)=>pre.fullCode.replace("x","")))
        setTurlerDis(false)
        services.getAxios('PostaAdresleri/Turler?listeId='+postaListesi+'&ulkeId='+value).then((res)=>{
            setTurler(res.data)
        }).catch((err)=>{
            toast.error('İşletme türleri yüklenirken bir hata oluştu.', {duration:3000})
        })
    }else{
        setTurlerDis(true)
    }
}

const OnclickBtnSil = ()=>{
    setBtnSilLoading(true)
    const toastId = toast.loading('E-posta adresleri siliniyor.')

    services.postAxios('PostaAdresleri/DeletePostaAdresleri',selected).then((res)=>{
        let newListe=[]
        postaAdresleri.forEach((item)=>{
           let durum =true;
           selected.forEach((itemSelected)=>{
              if(item.id === itemSelected)
                 durum=false;
           })
           if(durum)
              newListe.push(item)
        })
        setPostaAdresleri(newListe)
        toast.success('E-posta adresleri silindi.',{id:toastId, duration:2500})
        setBtnSilLoading(false)
    }).catch(()=>{
        toast.error('E-posta adresleri silinirken bir hata oluştu.', {id:toastId, duration:3000})
        setBtnSilLoading(false)
    })
    
}

function ListeSil(){
    setOpen(false)
    const toastId=toast.loading('E-posta listesi siliniyor.')

    services.deleteAxios('PostaListeleri/DeletePostaListesi?id='+postaListesi).then(()=>{
        toast.success('E-posta listesi silindi.', {id:toastId, duration:2500})
        setPostaListeleri(postaListeleri.filter((item)=>item.id !== postaListesi))
        OnChangePostaListesi('')
    }).catch(()=>{
        toast.error('E-posta listesi silinirken bir hata oluştu.', {id:toastId, duration:3000})
    })
}
    return(
        <>
          <FormControl fullWidth className="mb-3" size="small">
            <InputLabel id="demo-simple-select-label-liste">E-posta Listeleri</InputLabel>
            <Select
               labelId="demo-simple-select-label-liste" value={postaListesi}
               id="demo-simple-select-liste" onChange={(event)=>OnChangePostaListesi(event.target.value)}
               label="E-posta Listeleri">
                  {postaListeleri?.map((item)=>(
                     <MenuItem value={item.id}>{item.ad}</MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" className="mb-3" disabled={ulkelerDis}>
                    <InputLabel id="filtre-ulke">Ülkeler</InputLabel>
                    <Select disabled={ulkelerDis}
                    labelId="filtre-ulke" value={ulkeFiltresi} name={"asdsa"}
                    id="demo-simple-select-filtre-ulke" onChange={(event,child)=>{OnChangeUlkeler(
                        event.target.value, child.props.name)}}
                    label="Ülkeler">
                        {ulkeler?.map((item)=>(
                            <MenuItem value={item.id} name={item.name}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth size="small" className="mb-3" disabled={turlerDis}>
                    <InputLabel id="filtre-tur">İşletme Türleri</InputLabel>
                    <Select disabled={turlerDis}
                    labelId="filtre-ulke" value={turFiltresi}
                    id="demo-simple-select-filtre-tur" onChange={(event)=>{setTurFiltresi(event.target.value)}}
                    label="İşletme türleri">
                        <MenuItem value=""><em>Hiçbiri</em></MenuItem>
                        {turler?.map((item)=>{
                            if(item.count > 0){return(        
                                <MenuItem value={item.turu}>{item.turu} ({item.count})</MenuItem>)
                            }})}
                    </Select>
                </FormControl>
          <div className="row justify-content-between">
            <div className="col-md-auto col-sm-12">
                <div className="row">
                <div className="col-md-auto col-sm-12 mb-3">
                    <LoadingButton fullWidth variant="outlined" color="error" endIcon={<DeleteIcon/>}
                    className="text-capitalize" onClick={()=>{setOpen(true)}} disabled={listeSilDis}>
                        E-posta Listesini Sil</LoadingButton>
                </div>
                {/* <div className="col-md-auto col-sm-12 mb-3 p-md-0">
                    <LoadingButton fullWidth variant="outlined" color="error" endIcon={<DeleteIcon/>}
                    className="text-capitalize" onClick={()=>{}} disabled={ulkeSilDis}>
                        Ülkeyi Sil</LoadingButton>
                </div> */}
                </div>
            </div>
            <div className="col-md-auto col-sm-12">
                <div className="row justify-content-end">
                <div className="col-md-auto col-sm-12 mb-3 p-md-0">
                    <LoadingButton variant="outlined" color="success" endIcon={<SaveIcon/>} disabled={btnExcelDis}
                     fullWidth className="text-capitalize" loading={btnExcelLoading} onClick={Excel}>
                        Excel Olarak İndir
                    </LoadingButton>
                </div>
                <div className="col-md-auto col-sm-12 mb-3">
                    <LoadingButton variant="outlined" endIcon={<DeleteIcon/>} disabled={silBtnDis}
                     onClick={OnclickBtnSil} loading={btnSilLoading} fullWidth className="text-capitalize" color="error">
                        E-posta Adreslerini Sil
                    </LoadingButton>
                </div>
                </div>
            </div>
          </div>
          <TablePostaAdresleri Message={mesaj} backdrop={backdrop} PostaAdresleri={postaAdresleri} 
          TopPage={topPage} ChangePage={(value)=>{GetPostaAdresleri(value)}} selected={selected} 
          setSelected={setSelected} prefixes={prefix}/>
          
          <Dialog open={open} onClose={()=>setOpen(false)} maxWidth="xs" fullWidth>
            <DialogTitle>E-posta Listesi Silinecek</DialogTitle>
            <DialogContent>
            <DialogContentText className="mb-3">
                E-posta listesini silmek istediğinize emin misiniz? <br/>
                E-posta listesine kayıtlı bütün adresler silinecek.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>{setOpen(false)}}>İptal</Button>
            <Button onClick={ListeSil}>Sil</Button>
            </DialogActions>
        </Dialog>
        </>
    )
            }

export default EmailAddresses