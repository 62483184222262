import React, { useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentPasteOffSharpIcon from '@mui/icons-material/ContentPasteOffSharp';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Table, TableBody, TableCell, 
   TableRow, TableHead, TableContainer,
  Button, Dialog, DialogActions, 
  DialogTitle, Backdrop} from '@mui/material';
import { toast } from "react-hot-toast";
import request from '../services';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import LoadingButton from '@mui/lab/LoadingButton';
import Pagination from '@mui/material/Pagination'
import services from '../services';
import Chip from '@mui/material/Chip';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
  
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

  const DenseTable = ({liste, loading, mesaj, TopPage, ChangePage, selected, setSelected, setDurum}) => {
    const [progress, setProgress] = React.useState(55);
    const [allSelected, setAllSelected] = React.useState(false);
    const isSelected = (name) => selected.indexOf(name) !== -1;
  
    const handleClick = (event, name, durum) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      
      if(newSelected.length == liste.length)
        setAllSelected(true)
      else
        setAllSelected(false)
      setSelected(newSelected);
      setDurum(durum)
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = liste?.map((n) => n.id);
        setAllSelected(true)
        setSelected(newSelected);
        return;
      }
      setAllSelected(false)
      setSelected([]);
    };
  
    useEffect(()=>{
      setAllSelected(false)
      setSelected([])
    },[liste])

    // async function Iptal(id){
    //   const toastId = toast.loading('E-posta kazıma işlemi iptal ediliyor.')
    //   await services.getAxios('StopBackgroundService?id='+id).then(async()=>{
    //     await services.getAxios('StartBackgroundService').then(()=>{}).catch(()=>{})
    //     toast.success('E-posta kazıma işlemi iptal edildi.',{id:toastId, duradion:2500})
    //       ChangePage(1);
    //     }).catch(()=>{
    //       toast.error('E-posta kazıma işlemi iptal edilemedi.',{id:toastId, duration:3000})
    //     })
    //   //   services.postAxios('Scraper/ScraperCancel?id'+id).then(()=>{
    //   // }).catch(()=>{
    //   //   toast.error('E-posta kazıma işlemi iptal edilemedi.',{duration:3000})
    //   //   setIptalLoading(false)
    //   // })
    // }

  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={allSelected}
                onChange={(event) => {handleSelectAllClick(event)}}
              />
            </TableCell>
            <TableCell>Ülke</TableCell>
            <TableCell align='center'>E-posta Listesi</TableCell>
            <TableCell align='center'>Kelime</TableCell>
            <TableCell align='center'>Geçen Süre</TableCell>
            {/* <TableCell align='center'>Bitiş Tarihi</TableCell> */}
            <TableCell align='center'>E-posta Sayısı</TableCell>
            <TableCell align='center'>Telefon Sayısı</TableCell>
            <TableCell align='center'>Durum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {liste?.length > 0 ? (liste?.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            
            return (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="checkbox">
                  <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onChange={(event)=> handleClick(event, row.id,row.durum)}
                    />
              </TableCell>
              <TableCell component="th" scope="row" className='text-truncate' style={{maxWidth:"200px"}}>
                {row.ulke}</TableCell>
              <TableCell align='center' className='text-truncate' style={{maxWidth:"200px"}}>
                {row.liste}
              </TableCell>
              <TableCell align='center' className='text-truncate' style={{maxWidth:"200px"}}>
                {row.kelime}
              </TableCell>
              <TableCell align='center'>
                <Tooltip title={row.gecenSure}>
                  <WatchLaterOutlinedIcon/>
                </Tooltip>
              </TableCell>
              {/* <TableCell align='center'>
                {row.basZamani? row.basZamani.substring(0,row.basZamani.length-3).split('T').join(' ') :"-"}
              </TableCell>
              <TableCell align='center'>
                {row.bitZamani ? row.bitZamani.substring(0,row.bitZamani.length-3).split('T').join(' ') : "-"}
              </TableCell> */}
              <TableCell align='center'>
                {row.epostaSayisi || "-"}
              </TableCell>
              <TableCell align='center'>
                {row.telefonSayisi || "-"}
              </TableCell>
              <TableCell align='center'>
              <Chip label={row.durum ===1 ? "Duraklatıldı":row.durum===2?"Tamamlandı":row.durum===3?"Sürüyor"
              :"Sırada"} color={row.durum===1?"warning":row.durum===2?"success":row.durum===3?"info":"default"}
              size="small"/>
              </TableCell>
              {/* <TableCell align='center'>
                {row.bitZamani ? <Chip label="Tamamlandı" color="success" size="small"/>: 
                row.iptalTarihi ? 
                <Tooltip title={row.iptalTarihi.substring(0,row.iptalTarihi.length-3).split('T').join(' ')}>
                  <Chip label="İptal edildi" color="error" size="small"/>
                </Tooltip>:
                <Tooltip title="İptal et">
                  <LoadingButton color='error' size='small' onClick={()=>Iptal(row.id)}>
                  <CloseRoundedIcon/>
                </LoadingButton></Tooltip>}
              </TableCell> */}
            </TableRow>
          )})) : (<TableRow>
              <TableCell align='center' colSpan={8}>
                {loading? (<CircularProgress size={35} color="inherit" />): (
                    <span className='fs-3 text-black-50 d-flex justify-content-center'>
                      <ContentPasteOffSharpIcon fontSize='large' className='me-2'>
                        </ContentPasteOffSharpIcon>{mesaj || 'Aramanızla eşleşen bir yer bulunamadı.'}
                      </span>
                )}
              </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
    <Pagination count={TopPage} variant="outlined" 
        className={classNames('d-flex mt-3',{'d-none': TopPage > 1 ? false : true})} 
        onChange={(event, value)=> ChangePage(value)}/>
    </>
  );
}

export default DenseTable;