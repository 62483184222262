import React, { useEffect, useState } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import CircularProgress from '@mui/material/CircularProgress'
import Pagination from '@mui/material/Pagination'
import Backdrop from '@mui/material/Backdrop'
import DeleteIcon from '@mui/icons-material/Delete';
import ContentPasteOffSharpIcon from '@mui/icons-material/ContentPasteOffSharp';
import classNames from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import ws from '../img/icons8-whatsapp-48.png';

const TablePostaAdresleri=({Message, backdrop, PostaAdresleri, TopPage, ChangePage, selected, setSelected, prefixes})=>{
  const [allSelected, setAllSelected] = React.useState(false);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    
    if(newSelected.length == PostaAdresleri.length)
      setAllSelected(true)
    else
      setAllSelected(false)
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = PostaAdresleri?.map((n) => n.id);
      setAllSelected(true)
      setSelected(newSelected);
      return;
    }
    setAllSelected(false)
    setSelected([]);
  };

  useEffect(()=>{
    setAllSelected(false)
    setSelected([])
  },[PostaAdresleri])

    return(
      <>
        <TableContainer component={Paper} className='position-relative'>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop} className='position-absolute' style={{backgroundColor:'#cfcfcf80'}}
          >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Table sx={{ minWidth: 400 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={allSelected}
                onChange={(event) => {handleSelectAllClick(event)}}
              />
            </TableCell>
            <TableCell>Web Sitesi</TableCell>
            <TableCell align="center">İşletme Türü</TableCell>
            <TableCell align="center">E-posta Adresi</TableCell>
            <TableCell align="center">Telefon Numarası</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {PostaAdresleri.length > 0 ? (PostaAdresleri?.map((row,index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const tel = row.telefon.replaceAll(/-| |(|)/gi, "");
            return(
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="checkbox">
                  <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onChange={(event)=> handleClick(event, row.id)}
                    />
              </TableCell>
              <TableCell component="th" scope="row" className='text-truncate' style={{maxWidth:"300px"}}>
                {row.webSitesi}</TableCell>
                <TableCell component="th" align="center" className='text-truncate' style={{maxWidth:"300px"}}>
                {row.turu}</TableCell>
              <TableCell component="th" align="center" className='text-truncate' style={{maxWidth:"300px"}}>
                {row.adres}</TableCell>
              <TableCell component="th" align="center" className='text-truncate' style={{maxWidth:"300px"}}>
                {row.telefon|| "-"}{row.telefon ? prefixes?.map((x)=>{if(tel.startsWith(x))
                  {return (<a href={"https://wa.me/"+tel.replace("+","")} target="blank" key={index}>
                    <img src={ws} style={{height:"20px", marginLeft:"3px"}}/>
                  </a>)}}):""}</TableCell>
            </TableRow>
          )})) : (<TableRow>
              <TableCell align='center' colSpan={8}>
                
                    <span className='fs-3 text-black-50 d-flex justify-content-center'>
                      <ContentPasteOffSharpIcon fontSize='large' className='me-2'>
                        </ContentPasteOffSharpIcon>{Message}
                      </span>
                
              </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
        </TableContainer>
        <Pagination count={TopPage} variant="outlined" 
        className={classNames('d-flex mt-3',{'d-none': TopPage > 1 ? false : true})} 
        onChange={(event, value)=> ChangePage(value)}/>
      </>
    )
}

export default TablePostaAdresleri